<template>
  <v-text-field
    v-model="control.model[control.name]"
    v-bind="control"
    class="jexy-string"
  />
</template>

<script>

// Mixin
import Control from './Mixin'

export default {
  name: 'String',
  mixins: [ Control ]
}
</script>
